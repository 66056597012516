import React from 'react';

import './radioButton.css';

// Muestra el componente del radiobutton con su texto
const RadioButton = ({name, id, text, changeValue}) => {

    // Checkea el radio buton al clicar en el texto
    const changeValueRadio = (id) => {
        changeValue(id)
        document.getElementById(id).checked = true;
    }

    return (
        <div className={'container'}>
            <input id={id} name={name} type={'radio'} onChange={value => changeValue(id)}/>
            <p className={'textRadioButton'} onClick={() => changeValueRadio(id)}>{text}</p>
        </div>
    )
}

export default RadioButton;
