import React, {useEffect, useState} from 'react';

import './login.css';
import {ImageAssets} from '../../../utils/ImageAssets';
import {IconAssets} from '../../../utils/ImageAssets';

import {useNavigate} from 'react-router-dom';
import {message} from 'antd';
import loginStore from "../../../store/loginStore";
import {login} from '../../../services/user.service';

import Input from '../../../components/input/input.compnent';
import Button from '../../../components/button/button.component';

import {RSA } from 'hybrid-crypto-js';
import {updatePublicKey, savePrivateKey, getPrivateKey} from "../../../services/user.service";

// Vista de login
const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [disable, setDisable] = useState(false);
    const setLogin = loginStore(state => state.setLogin);

    let navigate = useNavigate();

    // Se ejecuta cada vez que cambian los campos de email y pasawor
    // Si los 2 campos estan llenos activa el boton sino lo desactiva
    useEffect(() => {
        if (!email || !password) {
            setDisable(true)
        } else {
            setDisable(false)
        }
    }, [email, password]);

    // Redirege a la pagina de recordar contraseña en el caso de haber introducido el email sino pide que introduzcas el email
    const goRememberPassword = () => {
        if (email) {
            navigate('/rememberPassword', {state: {email: email}});
        } else {
            message.error('Introduce tu correo para recordar la contraseña.');
        }
    }

    const signIn = async () => {
        // Hace la llamada al back para hacer el login
        await login(email, password).then(async res => {
            // Genera la instacia RSA para generar claves publicay privada
            let rsa = new RSA({
                keySize: 1024,
            });
            console.log('antes del if')
            // Si el usuario es coach
            if (res.data.user.user_type === 'coach') {
                console.log('despues del if', res.data.user.id)
                // LLama al server para recoger la clave privada, en el caso de que exista la clave privada
                await getPrivateKey(res.data.user.id).then(async resGetPrivateKey => {
                    console.log('Clave privada recogida', resGetPrivateKey);
                    // Guarad tanto clave publica como privada en localstorage
                    await localStorage.setItem('publickey', res.data.user.public_key);
                    await localStorage.setItem('privatekey', resGetPrivateKey.data);
                    setLogin(true);
                }).catch(async err => {
                    console.log('tenemos error')
                    // Si no existe una clave privada en la base de datos
                    // Genera tanto clave publica como clave privada
                    await rsa.generateKeyPairAsync().then(async keyPair => {
                        // Envia al back la clave privada para guardala en base de datos
                        await savePrivateKey(keyPair.privateKey).then(async resSavePrivateKey => {
                            // Guarda en localstorage tanto clave publica como privada
                            localStorage.setItem('publickey', keyPair.publicKey);
                            localStorage.setItem('privatekey', keyPair.privateKey);
                            // LLama al server para actualizar la clave publica del coach
                            await updatePublicKey(keyPair.publicKey).then(resp => {
                                console.log('public key changed correctly', resp.data);
                            }).catch(err => {
                                console.log('ERROR to change public key', err);
                            })
                            setLogin(true);
                        }).catch(err => {
                            console.log('Error al guarda la clave privada',err);
                        })
                    });
                })

            } else {
                // Si el usuario no es coach limpia localstorage y mustra un error con credenciales incorrectas
                localStorage.clear();
                message.error('Credenciales invalidas para el workplace.');
            }
        }).catch(err => {
            // Si el login no funciona muestra el mensaje de error
            message.error('No se ha podido realizar el login, credenciales incorrectas.');

        })
    }

    return (
        <div className={'containerLogin'}>
            <img className={'logo'} alt={''} src={ImageAssets.originalLogoAuth}/>
            <form className={'formLogin'}>
                <Input
                    iconLeft={IconAssets.at}
                    placeholder={'Email'}
                    type={'text'}
                    changeValue={value => setEmail(value)}
                />

                <Input
                    iconLeft={IconAssets.pass}
                    placeholder={'Contraseña'}
                    type={'password'}
                    changeValue={value => setPassword(value)}
                />

                <a className={'rememberPasswordLogin'} href={'/rememberPassword'} onClick={goRememberPassword}>¿Olvidaste tu contraseña?</a>

                <Button
                    disabled={disable}
                    text={'Iniciar sesión'}
                    press={() => signIn()}
                />
            </form>
        </div>
    )
}

export default Login;
