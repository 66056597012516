import React, {useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {message} from 'antd';

import './reportUser.css';

import Button from '../../components/button/button.component';
import {blockUser} from "../../services/chat.service";

const ReportUser = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [reason, setReason] = useState('');

    // Bloquea el usuario
    const reportUser = () => {
        // Detecta si se a completado el campo del motivo por el que se bloque el usuario
        if (reason !== '' && reason !== null) {
            // Envia al servidor el id del usuario que bloquea, el canal y la razon por la que se le bloquea
            blockUser(location.state.idUser, location.state.idChannel, reason).then(res => {
                console.log('Usuario baneado con exito', res.data);
                // Navega a la pagina de chats
                navigate('/chat');
            }).catch(err => {
                console.log('ERROR al bloquar a los usuarios', err);
            })
        } else {
            // Si el motivo no esta relleno dice que es necesario llenarlo
            message.error('Rellena el campo para bloquear al usuario.')
        }

    }
    
    return (
        <div className={'containerReportUser'}>
            <p className={'titleReportUser'}>¿Deseas bloquear este usuario?</p>

            <p className={'textReportUser'}>Si bloqueas este usuario ya no podrá interactuar contigo. Por favor indica los motivos:</p>

            <textarea
                className={'textareaReportUser'}
                placeholder={'Escriba aqui...'}
                onChange={value => setReason(value.target.value)}
            />

            <div className={'containerButtonsReportUser'}>
                <div className={'containerButton'}>
                    <Button
                        text={'Cancelar'}
                        secondary
                        press={() => navigate('/')}
                    />
                </div>
                <div className={'containerButton'}>
                    <Button
                        text={'Confirmar'}
                        press={() => reportUser()}
                    />
                </div>
            </div>
        </div>
    )
}

export default ReportUser;
