import React from 'react';
import {useNavigate, useLocation} from 'react-router-dom'
import { saveAs } from "file-saver";

import './article.css';
import {IconAssets} from '../../utils/ImageAssets';

import Button from '../../components/button/button.component';

// Vista del articulo
const Article = () => {
    const navigate = useNavigate();
    const location = useLocation();
    // Recoge datos de la vista anterior
    let article = location.state.post;

    // Descarga el archivo del servidor
    const downloadFile = () => {
        saveAs(`https://node.mybeatcoach.com/${article.file}`)
    }

    return (
        <div className={'containerArticle'}>
            <div className={'containerGoBackArticle'} onClick={() => navigate('/articles')}>
                <img className={'iconGoBackArticle'} alt={''} src={IconAssets.left}/>
                <p className={'goBackArticle'}>Volver</p>
            </div>

            <div className={'containerTitleArticle'}>
                <p className={'titleArticle'}>{article.title}</p>
                <div className={'containerButtonArticle'}>
                    <Button
                        text={'Descargar archivo'}
                        iconLeft={IconAssets.download}
                        press={() => downloadFile()}
                    />
                </div>
            </div>

            <p className={'descriptionArticle'}>{article.description}</p>

            <iframe title={'file'} className={'pdfArticle'} src={`https://node.mybeatcoach.com/${article.file}#toolbar=0`} width={'100%'} height={'700px'}/>
        </div>
    )
}

export default Article;
