import React from 'react'
import './sessionComponent.css'

import Avatar from '../avatar/avatar.component'
import moment from 'moment'

const Session = ({image, name, startDate, endDate, press}) => {
    moment.lang('es', {
        months: 'Enero_Febrero_Marzo_Abril_Mayo_Junio_Julio_Agosto_Septiembre_Octubre_Noviembre_Diciembre'.split('_'),
        monthsShort: 'Enero._Feb._Mar_Abr._May_Jun_Jul._Ago_Sept._Oct._Nov._Dec.'.split('_'),
        weekdays: 'Domingo_Lunes_Martes_Miercoles_Jueves_Viernes_Sabado'.split('_'),
        weekdaysShort: 'Dom._Lun._Mar._Mier._Jue._Vier._Sab.'.split('_'),
        weekdaysMin: 'Do_Lu_Ma_Mi_Ju_Vi_Sa'.split('_')
      }
    );

    return (
        <div className='containerSession' onClick={press}>
            <Avatar
                image={image}
            />
            <div className='containerTextSession'>
                <span className='nameSession'>{name}</span>
                <span className='dateSession'>{moment(startDate).format('dddd, DD MMMM')} {moment(startDate).format('HH:mm')} - {moment(endDate).format('HH:mm')}</span>
            </div>
        </div>
    )
}

export default Session