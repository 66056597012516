import React, {useState, useEffect} from 'react';
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import {Eventcalendar, setOptions, localeEs, momentTimezone} from '@mobiscroll/react';
import * as moment from 'moment';

import {getCalendar, getHolidaysDays} from '../../services/user.service';
import {getProfile} from '../../services/user.service';
import './diary.css';

momentTimezone.moment = moment;



setOptions({
    locale: localeEs,
    theme: 'ios',
    themeVariant: 'light'
});

const Diary = () => {
    const [typeCalendar, setTypeCalendar] = useState('week');
    const [myEvents, setMyEvents] = useState([]);
    const [invalidDays, setInvalidDays] = useState([]);

    // Se ejecuta cuando entra a la vista
    useEffect(() => {
        // Recoge el perfil del coach
        getProfile().then(user => {
            console.log('Perfil recogido con exito', user.data);
            // Recoge el calendario del coach
            getCalendar(user.data.id).then(res => {
                let events = [];
                // Recorre el calendario para guardar susa datos en un array
                res.data.forEach(event => {
                    events.push(event.recurring);
                })
                setMyEvents(events);
            }).catch(err => {
                console.log('ERROR al recoger los eventos del calendario', err);
            })
        }).catch(err => {
            console.log('ERROR al recoger el perfil', err);
        })
        
        // Recoge los dias de vacaciones qu etien el coach
        getHolidaysDays().then(res => {
            console.log('Vacaciones recogidas con exito', res.data);
            // Recorre los dias de vacaciones les pone un titulo y los guarda en un array para mostrarlos
            let invalids = [];
            res.data.forEach(invalid => {
                invalid.title = 'Festivo';
                invalid.cssClass = 'invalid';
                invalids.push(invalid);
            })
            setInvalidDays(invalids);
        }).catch(err => {
            console.log('ERROR al recoger las vacaciones', err);
        })
    }, []);

    return (
        <div className={'containerDiary'}>
            <div className={'containerHeaderDiary'}>
                <select
                    className={'selectDiary'}
                    onChange={value => setTypeCalendar(value.target.value)}
                >
                    <option value={'day'}>Dia</option>
                    <option value={'week'} selected>Semana</option>
                    <option value={'month'}>Mes</option>
                </select>

                <div className={'optionDiary'}>
                    <div className={'workDiary'}></div>
                    <p className={'textOptionDiary'}>Horario laboral</p>
                </div>

                <div className={'optionDiary'}>
                    <div className={'notWorkDiary'}></div>
                    <p className={'textOptionDiary'}>Horario no laboral</p>
                </div>

                <div className={'optionDiary'}>
                    <div className={'partyDiary'}></div>
                    <p className={'textOptionDiary'}>Festivo</p>
                </div>
            </div>
            {typeCalendar !== 'month' ?
                <Eventcalendar
                    className={'calendar'}
                    themeVariant="light"
                    newEventText={'Work'}
                    event
                    dragToCreate={false}
                    clickToCreate={false}
                    dragToMove={false}
                    colorEventList={true}
                    day
                    colors={['#0F1841']}
                    invalid={invalidDays}
                    data={myEvents}
                    view={{
                        schedule: {
                            type: typeCalendar,
                            startDay: 1,
                            endDay: 0,
                            startTime: '06:00',
                            endTime: '22:00',
                        }
                    }}
                />
                :
                <Eventcalendar
                    className={'calendar'}
                    locale={localeEs}
                    theme="ios" 
                    themeVariant="light"
                    clickToCreate={false}
                    dragToCreate={false}
                    dragToMove={false}
                    dragToResize={false}
                    eventDelete={false}
                    data={myEvents}
                    colors={['#0F1841']}
                    invalid={invalidDays}
                    view={{calendar: { 
                        labels: true,
                        popover: true
                    }}}
                />
            }
        </div>
    )
}

export default Diary;
