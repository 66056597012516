import React, {useState, useRef, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import * as moment from 'moment';
import io from 'socket.io-client';

import './chat.css';
import {IconAssets, ImageAssets} from '../../utils/ImageAssets';

import ChatItem from '../../components/chatItem/chatItem.component';
import Writer from '../../components/writer/writer.component';
import Message from '../../components/message/message.component';
import InfoUserCard from '../../components/infoUserCard/infoUserCard.component';
import MenuChat from '../../components/menuChat/menuChat.component';
import {getConversations, finishSession, changeStatusToEnterVideoCall, getVideocallUuid, userStartWriting, userEndWriting} from '../../services/chat.service';
import {getProfile} from "../../services/user.service";

import {sendMessage, getChannelMessages} from '../../services/chat.service';
import Searcher from "../../components/searcher/searcher.component";
import { Crypt } from 'hybrid-crypto-js';
import sound from '../../assets/correct-ding.mp3'
import { Alert, List } from 'antd';

const Chat = props => {
    const navigate = useNavigate();

    const [chatSelected, setChatSelected] = useState(null);
    const [allChats, setAllChats] = useState(null)
    const [currentUserId, setCurrentUserId] = useState(null);
    /*Actualizacion*/
    const [idsWritingChats, setIdsWritingChats] = useState([])
    /*Actualizacion*/

    const [showUserCard, setShowUserCard] = useState(false);
    const [showMenu, setShowMenu] = useState(false);
    const [chats, setChats] = useState(null);
    const [idUser, setIdUser] = useState(null);

    const [messages, setMessages] = useState([]);
    const [file, setFile] = useState(null);
    const [filePreview, setFilePreview] = useState(null);
    const [typeData, setTypeData] = useState(null);
    const [actualConversation, setActualConversation] = useState();
    const [fileSended, setFileSended] = useState(false);
    const [messagesNotRead, setMessagesNotRead] = useState([]);

    const soundAudio = useRef(false)

    const [isHiddenPage, setIsHiddenPage] = useState(false);
    let chatId = null
    //const [channelNextId, setChannelNextId] = useState(location.state.channelId);


    const inputRef = useRef(null);
    const socketRef = useRef(null);
    const scrollRef = useRef(null);
    const audio = new Audio(sound)

    document.addEventListener('visibilitychange', handleVisibilityChange, false);

    useEffect(() => {
        audio.addEventListener('error', () => {
            console.log('error al reproducir el audio')
        })
        //RECOGE LAS CONVERSACIONES ABIERTAS
        getConversations(1).then(conversations => {
            setAllChats(conversations.data);
            setChats(conversations.data);

            //CONECTA EL SOCKET
            let token =localStorage.getItem('token');
            console.log('ENTER IN SOCKET CONNECT', token)
            socketRef.current = io('https://node.mybeatcoach.com/nsp-io-chat', {query: `token=${token}`});

            //OBTIENE LOS EVENTOS DEL SOCKET
            getProfile().then(res => {
                console.log('getProfileHome', res.data);
                socketEvents(res.data.id);
                setCurrentUserId(res.data.id);
            }).catch(err => {
                console.log('Error to get profile',err);
            })
        }).catch(err => {
            console.log('ERROR al recoger las conversaciones', err);
        })

        return () => {
            socketRef?.current?.disconnect();
            console.log('EL SOCKET SE DESCONECTA')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isHiddenPage]);

    function handleVisibilityChange() {
        userEndWriting(chatSelected, idUser).then(res => {
            console.log('dejando de escribir con exito')
        }).catch(err => {
            console.log('El usuario no ha podido dejar de escribir', err)
        })
        if (document.hidden) {
          console.log('La página está oculta. Posiblemente el sistema está inactivo.');
          //isPageHidden = true;
          setIsHiddenPage(true);
          socketRef?.current?.disconnect();
        } else {
          console.log('La página está visible. Posiblemente el sistema se ha reactivado.');
          //isPageHidden = false;
          setIsHiddenPage(false);
          window.location.reload();
        }
      }
      

    // Antes de eliminar la pagina desconecta el socket
    window.onbeforeunload = function() {
        console.log('enter before')
        socketRef?.current?.disconnect();
    };

    // Se ejecuta cada vez que cambias de conversacion en el chat
    useEffect(() => {
        setTimeout(() => {
            // Desactiva el evento del socket para recibir mensajes
            socketRef?.current?.off('message-published');
            socketRef?.current?.off('unread-messages');
            // Vulve a conectar con el evento del socket para recibir mensajes
            socketRef?.current?.on('message-published', (e) => {
                if (e.user_id === idUser) {
                    audio.play()
                    let userStorage = localStorage.getItem('USER');
                    socketRef?.current?.emit('read-message', {messageId: e.id, userId: JSON.parse(userStorage).user.id})
                }
                // Si la conversacion en la que estas actualmente y la que te llega por el nuevo mensaje publicado son la smismas
                if (Number(e.channel_id) === Number(chatSelected)) {
                    // Recoge el usuario de localstorage
                    if (e.user_id === idUser) {
                        let userStorage = localStorage.getItem('USER');
                        socketRef?.current?.emit('read-message', {messageId: e.id, userId: JSON.parse(userStorage).user.id})
                    }
                    // Marca el mensaje como leido
                    /*if (e.channel_id == chatSelected) {
                        console.log('enter in read mesage')
                        socketRef?.current?.emit('read-message', {messageId: e.id, userId: JSON.parse(userStorage).user.id})
                    }*/
                    // Añade el mensaje al array para mostrarlo en la vista
                    setMessages(messages => [e, ...messages]);
                } else {
                    // SI la conversacion qu ellega por el mensaje no es la misma en la qu estamos actualmete
                    // Vuelve a recoger las conversaciones para mostrar sus ultimos mensajes actualizados
                    getConversations(1).then(conversations => {
                        setAllChats(conversations.data);
                        setChats(conversations.data);
                    }).catch(err => {
                        console.log('ERROR al recoger las conversaciones en el socket', err)
                    })
                }
            });

            socketRef?.current?.on('unread-messages', (e) => {
                console.log('LOS MENSAJES SIN LEER', e);
                if (e.unreadTotal > 0) {
                    console.log('entra igual')
                    const playPromise = audio.play();
                    if (playPromise !== undefined) {
                        playPromise.catch(error => {
                            console.error('Error al reproducir el audio:', error);
                            soundAudio.current = true
                        });
                    }
                }
                
                setMessagesNotRead(e.channels);
            })
        }, 500)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chatSelected]);

    /*useEffect(() => {
        if (recentlyMessage) {
            console.log('THIS IS A RECENTLY MESSAGE', recentlyMessage);
        }

    }, [recentlyMessage])*/

    const socketEvents = (coachId) => {
        console.log('this is a coach id', coachId)
        //ENVIAMOS EL CHANNELID A NULL PARA HACER EL JOIN SOBRE TODAS LAS CONVERSACIONES NO SOBRE UNA CONCRETA
        socketRef.current.emit('join_all', {
            channelId: null,
            userId: coachId,
            email: 'rmorenor97@gmail.com',
            socketId: socketRef?.current?.id,
            platform: 'web',
            appVersion: 'web'
        });

        socketRef?.current?.on('connect', () => {
            console.log('CONECT TO SOCKET', socketRef.current.id);
        });

        socketRef?.current?.on('welcome', () => {
            console.log('WELCOME TO SOCKET');
        });

        /*socketRef.current?.emit('coach-last-channel', {
            channel_prev_id: null,
            channel_next_id: location.state.channelId
        })*/

        socketRef?.current?.on('unread-messages', (e) => {
            console.log('LOS MENSAJES SIN LEER', e);
            console.log('chatIdddd', chatId)
            if (e.channels.length > 0) {
                const playPromise = audio.play();
                if (playPromise !== undefined) {
                    playPromise.catch(error => {
                        console.error('Error al reproducir el audio:', error);
                        soundAudio.current = true
                    });
                }
            }
            setMessagesNotRead(e.channels);
        })

        socketRef?.current?.on('new-channel', (e) => {
            console.log('new channel')
            socketRef?.current?.off('join_all');
            socketRef.current.emit('join_all', {
                channelId: null,
                userId: coachId,
                email: 'rmorenor97@gmail.com',
                socketId: socketRef.current.id,
                platform: 'web',
                appVersion: 'web'
            });
            getConversations(1).then(conversations => {
                setAllChats(conversations.data);
                setChats(conversations.data);
            }).catch(err => {
                console.log('ERROR al recoger las conversaciones en el socket', err)
            })
            console.log('HAVE NEW CHANNEL', e);
        })

        /*Actualizacion*/
        socketRef?.current?.on('start_writing', (e) => {
            let chat = localStorage.getItem('chatSelected')
            console.log('chatselected', chat)
            if (e.user_id === coachId && JSON.parse(chat) !== null && e.channel_id === JSON.parse(chat).chatId ) {
                setIdsWritingChats(idsWritingChats => [...idsWritingChats, e.channel_id])
                console.log('el usuario esta escribiendo')
            }
        });
        socketRef?.current?.on('end_writing', (e) => {
            let chat = localStorage.getItem('chatSelected')
            if (e.user_id === coachId && JSON.parse(chat) !== null && e.channel_id === JSON.parse(chat).chatId) {
                let ids = idsWritingChats.filter(id => id !== e.channel_id)
                setIdsWritingChats(ids)
                console.log('El usuario ha dejado de escribir', ids)
            }
        });
        /*Actualizacion*/

        socketRef?.current?.on('videocall-started', (e) => {
            // window.open(`https://videochat.innobing.net/sfu/${e.videocall_uuid}/${token}/`);
            console.log('videocall started',e);
            console.log('changes event',e);
            //setIdSession(e.videocall_uuid)
        });

        socketRef?.current?.on('error', () => {
            console.log('CONECT SOCKET ERROR');
        });

        socketRef?.current?.on('reconnect', (attemptNumber) => {
        console.log(`Intento de reconexión número ${attemptNumber}`);
        });
        
        socketRef?.current?.on('reconnect_error', (error) => {
        console.error('Error durante la reconexión:', error);
        });

        socketRef?.current?.on('error', (e) => {
            console.log('CONECT SOCKET error', e);
            socketRef?.current?.disconnect();
            let token =localStorage.getItem('token');
            console.log('ENTER IN SOCKET CONNECT', token)
            socketRef.current = io('https://node.mybeatcoach.com/nsp-io-chat', {query: `token=${token}`});
            socketEvents(currentUserId);
        });

        socketRef?.current?.on('disconnect', () => {
            console.log('CONECT SOCKET DISCONNECT');
            //removePushNotifications();
        });
    }

    //Recoge los mensajes de una conversacion al entrar
    const getMessagesChannel = (id) => {
        getChannelMessages(id).then(res => {
            console.log('Mensajes recogidos con exito', res.data);
            setMessages(res.data.Messages.reverse());
            let conversations = [];
            for (let i = 0; i < chats.length; i++) {
                if (chats[i].id !== id) {
                    conversations.push(chats[i]);
                } else {
                    conversations.push(res.data);
                }
            }
            setAllChats(conversations);
            setChats(conversations);
            setActualConversation(res.data);
        }).catch(err => {
            console.log('ERROR al recoger los mensajes', err);
        });
    }

    // Envia un nuevo mensaje
    const sendNewMessage = async (value) => {
        // Si existe mensaje de texto o archvio
        if (value.current.value !== '' || file !== null) {
            // Si existe archivo adjunta al texto que pueda tener el mensaje la fecha hasta la que estara disponible el archivo
            let newMessage = '';
            if (file !== null) {
                let expirationDate = moment().add(30, 'days').toDate().toLocaleDateString('es-ES', { day: '2-digit', month: '2-digit', year: 'numeric' })
                newMessage = value.current.value + " (Este fichero adjunto estará disponible hasta el " + expirationDate+")";
            }
            // Genera la instacia de CRYPT para encriptar el mensaje
            let crypt = new Crypt();
            let messageEncrypted;
            // Recoge la clave publica de localstorage para encriptar el mensaje
            let publicKeyAuthor = localStorage.getItem('publickey');
            // Encripta el mensaje con la clave publica dle usuario y del coach
            console.log('chatttttts', actualConversation)
            messageEncrypted = await crypt.encrypt([publicKeyAuthor, actualConversation.channelUsers.user.public_key], newMessage === '' ? value.current.value : newMessage);
            // Envia el mensaje al servidor
            sendMessage(chatSelected, chats[0].channelUsers.coach.id, messageEncrypted, file).then(res => {
                // Elimian todas la variables para poder enviar un nuevo mensaje de 0
                //setMessage('');
                setTypeData(null);
                setFile(null);
                userEndWriting(chatSelected, actualConversation.channelUsers.user.id).then(res => {
                    console.log('dejando de escribir con exito')
                }).catch(err => {
                    console.log('El usuario no ha podido dejar de escribir', err)
                })
                console.log('Mensaje enviado con exito', res);
            }).catch(err => {
                console.log('ERROR al enviar el mensaje', err);
                if (alert(err.response.data.error)) {
                    //setMessage('');
                    setTypeData(null);
                    setFile(null);
                } else {
                    //setMessage('');
                    setTypeData(null);
                    setFile(null);
                }
            })
        }
    }

    // Envia al servidor que se va a iniciar una videollamada
    const initVideochat = () => {
        socketRef?.current?.emit('initVideochat', {channelId: chatSelected});
    }

    // Cierra una sesión
    const closeSession = () => {
        // Envia al servidor el id de la sesion que se va a cerrar
        finishSession(chatSelected).then(res => {
            console.log('Sesion finalizada con exito', res);
            socketRef?.current?.emit('leave-channel', {channelId: chatSelected});
            // Navega a la pagina para valorar
            navigate('/rating', {state: {idChat: chatSelected}});
        }).catch(err => {
            console.log('ERROR al finalizar la sesion', err);
        })
    }

    // Maneja los archivos abiertos para poder mostrarlos y enviarlos
    const getFileObject = (value) => {
        console.log('return open')
        setShowMenu(false);
        let file = document.getElementById('inputFile').files[0];
        let type = file.type.split('/');
        console.log('TYPE', type)
        switch (type[0]) {
            case 'image':
                setTypeData('image');
            break;
            case 'video':
                setTypeData('video');
            break;
            case 'application':
                setTypeData('document');
            break;
            default:
                setTypeData('image');
            break;
        }
        setFile(value.target.files[0]);
        const reader = new FileReader();
        reader.readAsDataURL(value.target.files[0]);
        reader.onload = () => {
            setFilePreview(reader.result);
            console.log(reader.result);
        }
    }

    // Filtra los chats por el texto introducido en el buscador
    const searchChats = (value) => {
        if (value !== '') {
            let newChats = chats.filter(chat => chat.channelUsers.user.name.toLowerCase().includes(value.toLowerCase()))
            setChats(newChats);
        }
        else {
            setChats(allChats);
        }
    }

    // ENtra a una videollamada
    const enterVideoCall = async (uuidSession) => {
        // LLama al servidor para cambiar el estado del coach
        await changeStatusToEnterVideoCall(chatSelected).then(async res => {
            // LLama al servidor para recoger el id de la videollamada a la que se va a entrar
            await getVideocallUuid(chatSelected).then(async resp => {
                // Recoge el token de localstorage
                let token = await localStorage.getItem('token');
                // Abre una nueva ventana con el videochat (Se abre de esta manera para que ningun navegador bloque la ventana emergente)
                const a = document.createElement("a")
                a.href = `https://videochat.mybeatcoach.com/sfu/${resp.data}/${token}/null`
                a.target = "_blank"
                a.rel = "noopener"
                a.click()
            }).catch(err => {
                console.log('Error al recoger el id de la sesion', err);
            })
        }).catch(err => {
            console.log('ERROR al cambiar el estado dle usuario al entrar a videollamada', err)
        })
    }

    // Desencripta un mensaje
    const decriptMessage = (message) => {
        // Recoge la clave privada de localstorage para desencriptar el mensaje
        let privateKey = localStorage.getItem('privatekey');
        let crypt = new Crypt();
        try {
            console.log('privateKey', privateKey)
            console.log('messageCrypt', message)
            let messageDecripted = crypt.decrypt(privateKey, message);
            console.log('messageDecrypt', messageDecripted)
            return messageDecripted.message;
        } catch (err) {
            console.log('catch decript', err)
        }
    }

    /*Actualizacion*/
    //Envia al server si el usuario esta escribiendo para mostrarselo al otro usuario
    const userIsWriting = (value) => {
        console.log('chatselected', chatSelected)
        console.log('value', value)
        console.log('actual conver', actualConversation)
        if (value) {
            console.log('esta escribiendo')
            userStartWriting(chatSelected, actualConversation.channelUsers.user.id).then(res => {
                console.log('Escribiendo con exito')
            }).catch(err => {
                console.log('Error al empezar a escribir')
            })
        } else {
            console.log('el usuario ha dejado de escribir')
            userEndWriting(chatSelected, actualConversation.channelUsers.user.id).then(res => {
                console.log('dejando de escribir con exito')
            }).catch(err => {
                console.log('El usuario no ha podido dejar de escribir', err)
            })
            console.log('deja de escribir')
        }
    }
    /*Actualizacion*/

    const renderItem = (chat) => {
        console.log('chatttt', chat)
            return (
                <ChatItem
                    image={chat.channelUsers.user.badge}
                    fullName={`${chat.channelUsers.user.name} ${chat.channelUsers.user.lastname}`}
                    lastMessage={chat.Messages.length > 0 && decriptMessage(chat.Messages[0].content)}
                    timeLastMessage={chat.Messages.length > 0 ? chat.Messages[0].createdAt : ''}
                    isSpecialUser={chat.channelUsers.user.real_coach_id === chat.channelUsers.coach.id}
                    press={() => {
                        console.log('chatttt', chat)
                        socketRef?.current?.emit('join_channel', {channelId: chat.id});
                        setChatSelected(chat.id);
                        localStorage.setItem('chatSelected', JSON.stringify({chatId: chat.id, userId: chat.channelUsers.user.id}))
                        getMessagesChannel(chat.id);
                        //setUuidSelected(chat.videocall_uuid);
                        setIdUser(chat.channelUsers.user.id);
                        setActualConversation(chat)
                    }}
                    organization={chat.channelUsers.user.Organizations.length > 0 && chat.channelUsers.user.Organizations[0].name}
                    unreadMessages={messagesNotRead.length > 0 ? messagesNotRead.find(object => object.channel_id === chat.id) : chat.unreadMessages.length}
                />
            )
    }


    return (
        <>
        {
        chatSelected === null ?
        chats !== null &&
        <div className='containerMessages'>
            <div class="containerTitleAlert">
                <p className='titleMessages'>Mensajes</p>
                <Alert
                    message="Cierra este alert para escuchar el sonido de las notificaciones"
                    type="error"
                    closable
                    onClose={() => {
                        console.log('sound audio', soundAudio)
                        if (soundAudio.current) {
                            audio.play()
                            soundAudio.current = false
                        }
                    }}
                />
            </div>
            

            <Searcher changeValue={value => searchChats(value)}/>

            <div className={'containerChatsMessages'}>
                {chats &&
                        <List
                        style={{zIndex: 9}}
                        itemLayout={'vertical'}
                        size={'large'}
                        pagination={{
                            pageSize: 7,
                        }}
                        dataSource={chats}
                        renderItem={renderItem}
                />}
            </div>
        </div>
            :
        <div className={'containerBlocksChat'}>
            {showUserCard &&
                <InfoUserCard
                    user={actualConversation}
                    close={value => setShowUserCard(value)}
                />
            }

            {showMenu &&
                <MenuChat
                    inputRef={inputRef}
                    initVideochat={() => initVideochat()}
                    finishSession={() => closeSession()}
                    pressNoMenu={() => setShowMenu(false)}
                    idUser={idUser}
                    idChannel={chatSelected}
                />
            }
            <input id={'inputFile'} ref={inputRef} hidden={true} type={'file'} onChange={value => getFileObject(value)}/>

            <div className={'blockChatsChat'}>
                <div className={'headerChat'}>
                    <img className={'iconBackChat'} alt={''} src={IconAssets.back} onClick={() => {
                        setChatSelected(null);

                        let chat = localStorage.getItem('chatSelected')
                        userEndWriting(JSON.parse(chat).chatId, JSON.parse(chat).userId).then(res => {
                            console.log('Se ha dejado de escribir en el chat')
                            localStorage.setItem('chatSelected', null)
                        }).catch(err => {
                            localStorage.setItem('chatSelected', null)
                            console.log('No se ha podido dejar de escribir en el chat')
                        })
                    }}/>
                </div>
                {chats !== null &&
                    <div className={'containerChatsChat'}>
                        {chats.map((chat, index) => {
                            return (
                                <ChatItem
                                    image={chat.channelUsers.user.image}
                                    fullName={`${chat.channelUsers.user.name} ${chat.channelUsers.user.lastname}`}
                                    lastMessage={chat.Messages.length > 0 && decriptMessage(chat.Messages[0].content)}
                                    timeLastMessage={chat.Messages.length > 0 ? chat.Messages[0].createdAt : ''}
                                    size={'little'}
                                    organization={(chat.channelUsers.user.Organizations && chat.channelUsers.user.Organizations.length > 0) ? chat.channelUsers.user.Organizations[0].name : 'Desconocida'}
                                    isSpecialUser={chat.channelUsers.user.real_coach_id === chat.channelUsers.coach.id}
                                    unreadMessages={messagesNotRead.find(object => object.channel_id === chat.id)}
                                    press={() => {
                                        if (chat.id !== chatSelected) {
                                            console.log('press in conversation')
                                            socketRef?.current?.emit('join_channel', {channelId: chat.id});
                                            setChatSelected(chat.id);
                                            getMessagesChannel(chat.id)
                                            setActualConversation(chat)
                                            localStorage.setItem('chatSelected', JSON.stringify({chatId: chat.id, userId: chat.channelUsers.user.id}))
                                            //setUuidSelected(chat.videocall_uuid);
                                            setIdUser(chat.channelUsers.user.id);
                                        }
                                        /*if (chat.id !== channelNextId) {
                                            console.log('idCHAAAAAAAT', channelNextId)
                                            setChannelPrevId(location.state.channelId);
                                            setChannelNextId(chat.id);
                                            setIdChat(chat.id);

                                            getMessagesChannel(chat.id);

                                            socketRef.current?.emit('coach-last-channel', {
                                                channel_prev_id: location.state.channelId,
                                                channel_next_id: chat.id
                                            })
                                            location.state.channelId = chat.id
                                        }*/
                                    }}
                                />
                            )
                        })
                        }
                    </div>
                }
            </div>

            <div className={'containerChatChat'}>
                {actualConversation &&
                    <div className={'headerChat'}>
                        <div className={'containerImageUserChat'}>
                            <img className={'imageUserChat'} alt={''} src={actualConversation.channelUsers.user.image === null ? ImageAssets.userTest : (actualConversation.channelUsers.user.image.includes('https') ? actualConversation.channelUsers.user.image : `https://node.mybeatcoach.com/${actualConversation.channelUsers.user.image}`)} onClick={() => {
                                setShowUserCard(!showUserCard);
                                //setShowMenu(false);
                            }}/>
                        </div>
                        <div>
                            <p className={'nameUserChat'}  onClick={() => {
                                setShowUserCard(!showUserCard);
                                //setShowMenu(false);
                            }}>{`${actualConversation.channelUsers.user.name} ${actualConversation.channelUsers.user.lastname}`}</p>

                            {/*Actualizacion*/}
                            {idsWritingChats.includes(chatSelected) ?
                                <p className={'textWritingChat'}>Escribiendo...</p>
                                :
                                <p className={'textNoWritingChat'}>No escribiendo</p>
                            }
                            {/*Actualizacion*/}
                        </div>
                        <img className={'iconOptionsChat'} alt={''} src={IconAssets.options} onClick={() => {
                            setShowMenu(!showMenu);
                            setShowUserCard(false);
                        }}/>
                    </div>
                }
                <div className={'containerChat'}>
                    {typeData === null ?
                        <div id={'containerMessages'} ref={scrollRef} className={'containerMessagesChat'} onLoad={() => console.log('BOTTTTTOMMMM')}>
                            {messages.length > 0 &&
                            (messages.map(message => {
                                    return (
                                        <Message
                                            sender={Number(message.user_id) === Number(chats[0].channelUsers.coach.id)}
                                            message={message.content}
                                            idChat={message.channel_id}
                                            type={message.message_type}
                                            time={message.createdAt}
                                            file={message.file}
                                            pressFile={value => setFilePreview(value)}
                                            typeFile={value => setTypeData(value)}
                                            isSended={value => setFileSended(value)}
                                            press={() => {
                                                enterVideoCall(message.videocall_uuid)
                                            }}
                                        />
                                    )
                                })
                            )
                            }
                        </div>
                        :
                        <div className={'containerFilesChat'}>
                            {typeData === 'image' &&
                                <>
                                    <img className={'iconCloseChat'} alt={''} src={IconAssets.close} onClick={() => {
                                        setTypeData(null);
                                        setFile(null);
                                        setFilePreview(null);
                                        setFileSended(false);
                                    }}/>
                                    <img className={'imageFile'} alt={''} src={filePreview}/>
                                </>
                            }
                            {typeData === 'video' &&
                                <>
                                    <img className={'iconCloseChat'} alt={''} src={IconAssets.close} onClick={() => {
                                        setTypeData(null);
                                        setFile(null);
                                        setFilePreview(null);
                                        setFileSended(false);
                                    }}/>
                                    <video style={{width: '100%', height: '90%'}} src={filePreview} controls/>
                                </>
                            }
                            {typeData === 'document' &&
                                <>
                                    <img className={'iconCloseChat'} alt={''} src={IconAssets.close} onClick={() => {
                                        setTypeData(null);
                                        setFile(null);
                                        setFilePreview(null);
                                        setFileSended(false);
                                    }}/>
                                    <iframe title={'preview'} src={filePreview+'#toolbar=0'} width={'100%'} height={'90%'}/>
                                </>
                            }
                        </div>
                    }


                    {!fileSended &&
                        <Writer
                            //value={message}
                            //changeValue={value => setMessage(value)}
                            writing={value => userIsWriting(value)}
                            pressSender={(value) => sendNewMessage(value)}
                            sendMessage={(value) => sendNewMessage(value)}
                        />
                    }

                </div>
            </div>

        </div>
        }
        </>
    )
}

export default Chat;
