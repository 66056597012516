import React from 'react';

import './avatar.css';
import NotUser from '../../assets/img/notUser.png'

// Muestra las imagenes de los usuarios
const Avatar = ({image, status}) => {

    return (
        <div className='containerAvatar'>
            <img className={'imageNavBar'} alt={''} src={image ? (image.includes('https') ? image : `https://node.mybeatcoach.com/${image}`) : NotUser}/>

            {/*Segun el estado que llegue muestra el el punto de un color*/}
            {status === 'Disponible' ?
                <div className={'dotAvatarConnected'}></div>
                :
                (status === 'Busy') ?
                <div className={'dotAvatarOcupado'}></div>
                    :
                    (status === 'No disponible' ?
                        <div className={'dotAvatarDisconnected'}></div>
                        :
                        null
                    )
            }
        </div>
    )
}

export default Avatar;
