import React from 'react';
import {useNavigate} from 'react-router-dom'

import './closeSesion.css';
import {IconAssets} from '../../utils/ImageAssets';

import {logout} from '../../services/user.service';

import loginStore from "../../store/loginStore";
import {changeStatus} from "../../services/user.service";

// Muestra el componente dle navBar para cerrar sesion
const CloseSesion = () => {
    const navigate = useNavigate();
    const setLogin = loginStore(state => state.setLogin);

    // Cierra la sesion del coach
    const logoutUser = () => {
        logout().then(res => {
            modifyStatus(null);
            // Limpia localstorage
            localStorage.clear();
            // Navega a la pagina de login
            navigate('/');
            setLogin(false)
        }).catch(err => {

        })
    }

    // Modifica el estado del coach
    const modifyStatus = (status) => {
        let state = status === 'Disponible' ? 'Online' : 'Offline'
        console.log('STATUS', status)

        // Envia al servido rel nuevo estado del coach
        changeStatus(state).then(res => {
            console.log('Estado actualizado con exito', res.data);
        }).catch(err => {
            console.log('ERROR al actualizar e estado', err);
        })
    }

    return (
        <div className={'containerCloseSesion'} onClick={() => logoutUser()}>
            Cerrar sesión
            <img className={'iconCloseSesion'} alt={''} src={IconAssets.closeSesion}/>
        </div>
    )
}

export default CloseSesion;
