import React from 'react';

import './speciality.css';

const Speciality = ({text}) => {

    return (
        <div className={'containerSpeciality'}>
            {text}
        </div>

    )
}

export default Speciality;
